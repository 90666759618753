/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, IconButton, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Apartment, Flag, HomeWork, Mail, PhoneAndroid, WhatsApp} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOSPOTENCIALES} from "../../Constantes";
import {useEffect, useState} from "react";
import IngresoSelectFuncion from "../../Modulo_formularios/Componentes/IngresoSelectFuncion";
import {theme} from "../../Tema";
import {irWhatsApp} from "../../Utilidades/irWhatsApp";

const Formulario_Potenciales = ({...props}) => {
    const [estado, setEstado] = useState('')
    const [numero, setNumero] = useState('')
    const [pais,setPais] = useState('')

    useEffect(() => {

        let num = props.props.watch('celular')
        let pais_ = props.props.watch('pais')
        setNumero(num)
        setPais(pais_)

    }, [props.props.watch('celular')]);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectFuncion Icono={Flag} nombre={'Estado'} dato={'estado'}  {...props}
                                          opciones={ESTADOSPOTENCIALES} setDato={setEstado}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    {estado === 'Aceptada' &&
                        <Typography sx={{fontWeight: 500, lineHeight: 1.1, fontSize: 14}}>
                            Pasará a Empresarias y podrá crear su tienda
                        </Typography>
                    }
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}  {...props}
                                  editable={false}

                    />
                </Grid>

                <Grid item container lg={10} sm={10} xs={9} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={'celular'}  {...props}

                    />
                </Grid>

                <Grid item container lg={2} sm={2} xs={3} sx={{justifyContent: 'flex-end'}}>
                    <IconButton
                        onClick={() => irWhatsApp({num: numero, pais: pais})}
                        sx={{backgroundColor: theme.palette.primary.main}}>
                        <WhatsApp sx={{fill: '#fff'}}/>
                    </IconButton>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Mail} nombre={'Correo'} dato={'correo'}  {...props}
                                  editable={false}

                    />
                </Grid>


                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Apartment} nombre={'Pais'} dato={'pais'}  {...props}
                                  editable={false}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={HomeWork} nombre={'Ciudad'} dato={'ciudad'}  {...props}
                                  editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={HomeWork} nombre={'Sector Economico'} dato={'sector'}  {...props}
                                  editable={false}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto nombre={'Notas'} dato={'notas'} lineas={4}  {...props}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Potenciales