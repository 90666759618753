/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {MilitaryTech, Store} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOTIENDAS, ESTADOTIENDASREVISON} from "../../Constantes";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoSelectPropId from "../../Modulo_formularios/Componentes/IngresoSelectPropId";

const Formulario_Tienda_Medallas = ({...props}) => {
    const [medallas, setMedallas] = useState([])

    useEffect(() => {
        obtenerCol('medallas').then((dox) => {
            if (dox.res) {
                setMedallas(dox.data)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectPropId Icono={MilitaryTech} nombre={'Medalla Uno'} propiedad={'nombre'}
                                         propiedadId={'img'} datoId={'img0'}
                                         dato={'nombre0'}   {...props} opciones={medallas}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectPropId Icono={MilitaryTech} nombre={'Medalla Dos'} propiedad={'nombre'}
                                         propiedadId={'img'} datoId={'img1'}
                                         dato={'nombre1'}   {...props} opciones={medallas}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectPropId Icono={MilitaryTech} nombre={'Medalla Tres'} propiedad={'nombre'}
                                         propiedadId={'img'} datoId={'img2'}
                                         dato={'nombre2'}   {...props} opciones={medallas}

                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Tienda_Medallas