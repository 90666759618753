/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Delete, TaskAlt} from "@mui/icons-material";
import {useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Empresaria_Detalles from "../Formularios/Formulario_Empresaria_Detalles";
import {LoadingButton} from "@mui/lab";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {getID} from "../../Servicios/BD/useEscucharUsuarioToCorreo";

const Dialogo_Empresarias_Detalles = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const [cargando, setCargando] = useState(false)
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })

    const verificarUsuario = () => {

        let id = getID(datos.correo)
        setCargando(true)
        obtenerDoc('usuarios', id).then((dox) => {
            if (dox.res && dox.data) {
                alert(`Usuario encontrado Nombre: ${dox.data.nombre}`)
            } else {
                alert('Usuario no encontrado')
            }
            setCargando(false)
        })
    }

    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando datos')
            if (entidad.estado === 'Aceptada') {
                pasarEmpresaria(entidad)
            } else {
                guardarPotenciales(entidad)
            }

        })

    }

    const pasarEmpresaria = (entidad) => {
        let obj = entidad
        obj.estado = 'Nueva'
        guardarDoc('empresarias', obj).then((dox) => {
            if (dox.res) {
                borrarDoc('potenciales', obj.id).then((d) => {
                    cerrarCargador()
                    realizado()
                    cerrar()
                })

            }
        })

    }


    const guardarPotenciales = (entidad) => {
        guardarDoc('empresarias', entidad).then((dox) => {
            if (dox.res) {
                alert('Datos Actualizados')
                realizado()
                cerrar()
            } else {
                alert('Ups algo paso, intentalo nuevamente')
            }
            cerrarCargador()
        })
    }

    const borrar = () => {
        abrirCargador('Borrando Empresaria')
        borrarDoc('empresarias', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Empresaria_Detalles props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1}}>

                <LoadingButton
                    loading={cargando}
                    onClick={() => verificarUsuario()}
                    variant={'outlined'}
                >Verificar Usuario y constraseña</LoadingButton>

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Empresaria</Button>
                }
            </Grid>


        </Grid>

    )

}
export default Dialogo_Empresarias_Detalles