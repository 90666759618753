/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Store} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOTIENDAS, ESTADOTIENDASREVISON} from "../../Constantes";
import {useState} from "react";

const Formulario_Estado_Comentarios = ({...props}) => {
    const [pais, setPais] = useState('')
    const [departamento, setDepartamento] = useState('')


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Store} nombre={'Estado'}
                                   dato={'estado'}  {...props} opciones={ESTADOTIENDAS}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Comentarios para Empresaria'}
                                  dato={'comentarios'}  {...props}
                                  lineas={6}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Estado_Comentarios