/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Revision_Tienda from "../Formularios/Formulario_Revision_Tienda";

const Dialogo_Revision_Tienda = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando datos')

            let coleccion = 'revision_tiendas'

            if (entidad.estado === 'Publicada') {
                coleccion = 'tiendas'
            }


            guardarDoc(coleccion, entidad).then((dox) => {
                if (dox.res) {

                    if (coleccion === 'tiendas') {
                        borrarDoc('revision_tiendas', entidad.id).then((des) => {

                            alert('Cambios realizados con exito')
                            realizado()
                            cerrar()
                            cerrarCargador()

                        })
                    } else {
                        alert('Cambios realizados con exito')
                        realizado()
                        cerrar()
                        cerrarCargador()
                    }

                } else {
                    cerrarCargador()
                    alert('Ups, algo inesperado paso, intentalo nuevamente\nError: DT01')
                }

            })
        })

    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Revision_Tienda props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>

        </Grid>

    )

}
export default Dialogo_Revision_Tienda