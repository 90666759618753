/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Delete, TaskAlt} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Formulario_Potenciales from "../Formularios/Formulario_Potenciales";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Empresaria_Detalles from "../Formularios/Formulario_Empresaria_Detalles";
import Formulario_Empresaria_Tienda from "../Formularios/Formulario_Empresaria_Tienda";
import {getID} from "../../Servicios/BD/useEscucharUsuarioToCorreo";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";

const Dialogo_Empresarias_Tienda = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad, setEntidad} = useFormulario({valoresDefecto: {}})
    const [tienda,setTienda] = useState({})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })









    useEffect(() => {

        let idUsuarioEmpresaria = getID(datos.correo)

        obtenerDoc('usuarios', idUsuarioEmpresaria).then((dox) => {
            if (dox.res){
                let usuario = dox.data

                if ( usuario && usuario.idTienda){
                    obtenerDoc('revision_tiendas', usuario.idTienda).then((des) => {
                        if (des.res && des.data){
                            setTienda(des.data)
                            setEntidad(des.data)
                        }else{
                            obtenerDoc('tiendas', usuario.idTienda).then((des) => {
                                if (des.res && des.data){
                                    setTienda(des.data)
                                    setEntidad(des.data)
                                }
                            })
                        }
                    })
                }


            }
        })



    }, [datos]);
    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Empresaria_Tienda props={props} editable={false}/>
            </Grid>






        </Grid>

    )

}
export default Dialogo_Empresarias_Tienda