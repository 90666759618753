import algoliasearch from "algoliasearch";
import {ID, KEY, KEYWRITE} from "../Constantes";

export const guardarRegistro = ({obj, coleccion, id}) => {


    obj.objectID = id

    const client = algoliasearch(ID, KEYWRITE);
    const index = client.initIndex(coleccion);

    console.log(obj)
    // Search for "query string" in the index "contacts"
    return index.saveObject(obj, {
        autoGenerateObjectIDIfNotExist: false
    })





}