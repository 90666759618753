import {obtenerDoc} from "../Servicios/BD/obtenerDoc";
import {irWhatsApp} from "./irWhatsApp";

export const enviarWhatsAppEmpresaria = ({id}) => {

    obtenerDoc('empresarias', id).then((dox) => {
        if (dox.res) {
            let celular = dox.data.celular
            let pais = dox.data.pais
            irWhatsApp({num: celular, pais: pais})

        }
    })

}