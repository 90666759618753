/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOSEMPRESARIAS} from "../../Constantes";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import {CloudDownload, Edit, ManageSearch, Storefront, StoreMallDirectory} from "@mui/icons-material";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {LoadingButton} from "@mui/lab";
import Dialogo_Empresarias_Detalles from "../Dialogos/Dialogo_Empresarias_Detalles";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import {obtenerPorcentajeDosValores} from '../../Utilidades/obtenerPorcentajeDosValores'
import Dialogo_Empresarias_Tienda from "../Dialogos/Dialogo_Empresarias_Tienda";


const Seccion_Empresarias = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numEmpresariasNuevas, setNumEmpresariasNuevas] = useState(0)
    const [numEmpresarias,setNumEmpresarias] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'empresarias',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Empresarias_Detalles,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle de Empresaria'
    })
    const {Dialogo: DialogoTienda, abrir: abrirTienda} = useDialogo({
        Componente: Dialogo_Empresarias_Tienda,
        realizado: () => setRecargar(!recargar),
        titulo: 'Tienda de Empresaria'
    })
    const [valoresPedidos, setValoresPedidos] = useState([])
    const [cargando, setCargando] = useState(false)

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'ver' :
                abrir(row)
                break
            case 'tienda' :
                abrirTienda(row)
                break
        }

    }


    const llenarValores = () => {


        contarColeccion({coleccion: 'empresarias'}).then((dox) => {
            if (dox.res) {
                let numEmpresarias = dox.data
                setNumEmpresarias(dox.data)
                contarPropiedad({coleccion: 'empresarias', propiedad: 'estado', valor: 'Registrada'}).then((des) => {
                    if (des.res) {
                        let numRegistradas = des.data
                        let valores = adaptador_indicador({
                            titulo: 'Total empresarias',
                            valorActual: numEmpresarias,
                            valorAnterior: `${obtenerPorcentajeDosValores(numRegistradas, numEmpresarias)}%`,
                            detalle: 'Registradas',
                            tipo: 'nomoneda'
                        })

                        setValoresPedidos([valores])


                    }
                })

            }
        })
    }

    const exportarDatos = () => {
        setCargando(true)
        obtenerCol('empresarias').then((dox) => {
            if (dox.res) {
                let arr = dox.data
                const worksheet = XLSX.utils.json_to_sheet(arr);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                // Buffer to store the generated Excel file
                const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

                saveAs(blob, "Potenciales.xlsx");
            }
            setCargando(false)
        })
    }


    useEffect(() => {

        contarPropiedad({coleccion: 'empresarias', propiedad: 'estado', valor: 'Nueva'}).then((dox) => {
            if (dox.res) {
                setNumEmpresariasNuevas(dox.data)
            }
        })

        llenarValores()

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoTienda/>

            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Empresarias'} textoComplementario={'nuevas'}
                                      textoResaltado={`${numEmpresariasNuevas} Empresarias`}/>
            </Grid>

            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Indicadores valores={valoresPedidos} dir={'flex-end'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-end', marginTop: 2, marginBottom: -3}}>
                <LoadingButton variant={'contained'}
                               loading={cargando}
                               onClick={() => exportarDatos()}
                               endIcon={<CloudDownload/>}>Exportar datos</LoadingButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    estados={ESTADOSEMPRESARIAS}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numEmpresarias}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Empresarias

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    },
    {
        nombre: 'Pais',
        direccion: 'right',
        propiedad: 'pais',

    },
    {
        nombre: 'Ciudad',
        direccion: 'right',
        propiedad: 'ciudad',

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    }
]


const botonesTabla = [
    {
        nombre: 'Ver Detalle',
        icono: ManageSearch,
        id: 'ver'
    },
    {
        nombre: 'Tienda',
        icono: StoreMallDirectory,
        id: 'tienda'
    },

]


