/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Divider, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {WhatsApp} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Tienda_Basica from "../Formularios/Formulario_Tienda_Basica";
import Formulario_Estado_Comentarios from "../Formularios/Formulario_Estado_Comentarios";
import {enviarNotificacionUsuario} from "../../Utilidades/enviarNotificacionUsuario";
import {enviarWhatsAppEmpresaria} from "../../Utilidades/enviarWhatsAppEmpresaria";


const Dialogo_Tienda_Estado = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarTienda = () => {

        obtenerEntidad().then((entidad) => {
            let obj = entidad
            abrirCargador('Guardando cambios')
            guardarDoc('tiendas', obj).then((dox) => {
                if (dox.res) {
                    enviarNotificacionUsuario({
                        idUsuario: entidad.idPersona,
                        titulo: `El estado de tu tienda es ${entidad.estado}`,
                        mensaje: 'Revisa el estado de tu tienda y realiza los ajustes si es el caso'
                    })
                    alert('Cambios realizados con exito')
                    cerrar()
                    realizado()
                }
                cerrarCargador()
            })
        })
    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Tienda_Basica props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 3, marginBottom: 3}}>
                <Divider sx={{width: '100%'}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Estado_Comentarios props={props}/>
            </Grid>

            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    onClick={() => guardarTienda()}
                >
                    Guardar Cambios
                </Button>
            </Grid>

            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    disabled={!datos.idEmpresaria}
                    startIcon={<WhatsApp/>}
                    variant={'outlined'}
                    onClick={() => enviarWhatsAppEmpresaria({id: datos.idEmpresaria})}
                >
                    Enviar Mensaje
                </Button>
            </Grid>


        </Grid>

    )

}
export default Dialogo_Tienda_Estado