/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOPRODUCTOS} from "../../Constantes";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import {CloudDownload, Interests, ManageSearch, TaskAlt} from "@mui/icons-material";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {LoadingButton} from "@mui/lab";
import Dialogo_Producto_Revision from "../Dialogos/Dialogo_Producto_Revision";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import {obtenerPorcentajeDosValores} from "../../Utilidades/obtenerPorcentajeDosValores";
import Dialogo_Producto_Variante from "../Dialogos/Dialogo_Producto_Variante";
import Dialogo_Producto_Enviar from "../Dialogos/Dialogo_Producto_Enviar";

const Seccion_Catalogo = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const [valoresPedidos, setValoresPedidos] = useState([])
    const [cargando, setCargando] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'productos',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Producto_Revision,
        realizado: () => setRecargar(!recargar)
    })
    const {Dialogo: DialogoVariantes, abrir: abrirVariantes} = useDialogo({
        Componente: Dialogo_Producto_Variante,
        realizado: () => setRecargar(!recargar)
    })
    const {Dialogo: DialogoEnviar, abrir: abrirenviar} = useDialogo({
        Componente: Dialogo_Producto_Enviar,
        realizado: () => setRecargar(!recargar)
    })

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'editar' :
                abrir(row)
                break
            case 'variantes' :
                abrirVariantes(row)
                break
            case 'enviar' :
                abrirenviar(row)
                break
        }

    }

    const botones = []

    const llenarValores = () => {


        contarColeccion({coleccion: 'productos'}).then((dox) => {
            if (dox.res) {
                let numEmpresarias = dox.data
                contarPropiedad({coleccion: 'productos', propiedad: 'estado', valor: 'Publicado'}).then((des) => {
                    if (des.res) {
                        let numRegistradas = des.data
                        let valores = adaptador_indicador({
                            titulo: 'Total productos',
                            valorActual: numEmpresarias,
                            valorAnterior: `${obtenerPorcentajeDosValores(numRegistradas, numEmpresarias)}%`,
                            detalle: 'Publicados',
                            tipo: 'nomoneda'
                        })

                        setValoresPedidos([valores])


                    }
                })

            }
        })
    }

    const exportarDatos = () => {
        setCargando(true)
        obtenerCol('productos').then((dox) => {
            if (dox.res) {
                let arr = dox.data
                const worksheet = XLSX.utils.json_to_sheet(arr);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                // Buffer to store the generated Excel file
                const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

                saveAs(blob, "Productos.xlsx");
            }
            setCargando(false)
        })
    }


    useEffect(() => {


        contarPropiedad({
            coleccion: 'productos',
            propiedad: 'estado',
            valor: 'En Revision',
        }).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })

        llenarValores()

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoVariantes/>

            <DialogoEnviar/>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Catalogo'} textoComplementario={'en revision'}
                                      textoResaltado={`${numProductos} Productos`} botones={botones} difiere={true}/>
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end', marginTop: sCell ? -2 : 0}}>
                <Indicadores valores={valoresPedidos} dir={'flex-end'}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-end', marginTop: 2, marginBottom: -3}}>
                <LoadingButton variant={'contained'}
                               loading={cargando}
                               onClick={() => exportarDatos()}
                               endIcon={<CloudDownload/>}>Exportar datos</LoadingButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    estados={ESTADOPRODUCTOS}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Catalogo

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Categoria',
        propiedad: 'categoria',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Categoria',
        direccion: 'left',
        propiedad: 'categoria',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    },
    {
        nombre: 'N. Variantes',
        direccion: 'right',
        propiedad: 'variantes',
        tipo: 'contararray'

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    }
]

const botonesTabla = [
    {
        nombre: 'Editar',
        icono: ManageSearch,
        id: 'editar'
    },
    {
        nombre: 'Variantes',
        icono: Interests,
        id: 'variantes'
    },
    {
        nombre: 'Enviar',
        icono: TaskAlt,
        id: 'enviar'
    },

]



