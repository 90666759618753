/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Delete, TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Revision_Tienda from "../Formularios/Formulario_Revision_Tienda";
import Formulario_Medalla from "../Formularios/Formulario_Medalla";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";

const Dialogo_Medalla = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando datos')
            let obj = entidad
            subirImagenFile(entidad.img, 'medallas').then((imx) => {
                if (imx.res) {
                    obj.img = imx.data
                    obj.fecha = new Date().getTime()
                    guardarDoc('medallas', obj).then((dox) => {
                        if (dox.res) {

                            alert('Cambios realizados con exito')
                            realizado()
                            cerrar()
                            cerrarCargador()

                        } else {
                            cerrarCargador()
                            alert('Ups, algo inesperado paso, intentalo nuevamente\nError: DT01')
                        }

                    })

                }
            })


        })

    }

    const borrar = () => {
        abrirCargador('Borrando Potencial')
        borrarDoc('medallas', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Medalla props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Medalla</Button>
                }
            </Grid>

        </Grid>

    )

}
export default Dialogo_Medalla