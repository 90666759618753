/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Divider, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {WhatsApp} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Tienda_Basica from "../Formularios/Formulario_Tienda_Basica";
import Formulario_Estado_Comentarios from "../Formularios/Formulario_Estado_Comentarios";
import {enviarNotificacionUsuario} from "../../Utilidades/enviarNotificacionUsuario";
import {enviarWhatsAppEmpresaria} from "../../Utilidades/enviarWhatsAppEmpresaria";
import Formulario_Tienda_Medallas from "../Formularios/Formulario_Tienda_Medallas";


const Dialogo_Tienda_Medalla = ({realizado, datos, cerrar}) => {

    const getArr = (arr) => {

        let obj = {}

        for (let i = 0; i < arr.length; i++) {
            obj['nombre' + i] = arr[i].nombre
            obj['img' + i] = arr[i].img
        }

        return obj
    }

    const {
        props,
        obtenerEntidad
    } = useFormulario({valoresDefecto: datos && datos.medallas ? getArr(datos.medallas) : {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarDatos = () => {

        let obj = datos
        obtenerEntidad().then((entidad) => {
            let arr = []

            if (entidad.nombre0) {
                arr.push({
                    nombre: entidad.nombre0,
                    img: entidad.img0
                })
            }

            if (entidad.nombre1) {
                arr.push({
                    nombre: entidad.nombre1,
                    img: entidad.img1
                })
            }

            if (entidad.nombre2) {
                arr.push({
                    nombre: entidad.nombre2,
                    img: entidad.img2
                })
            }

            obj.medallas = arr

            abrirCargador('Guardando cambios')

            guardarDoc('tiendas', obj).then((dox) => {
                if (dox.res) {

                    alert('Cambios realizados con exito')
                    cerrar()
                    realizado()
                }
                cerrarCargador()
            })
        })
    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Tienda_Medallas props={props}/>
            </Grid>

            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    onClick={() => guardarDatos()}
                >
                    Guardar Cambios
                </Button>
            </Grid>

            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    disabled={!datos.idEmpresaria}
                    startIcon={<WhatsApp/>}
                    variant={'outlined'}
                    onClick={() => enviarWhatsAppEmpresaria({id: datos.idEmpresaria})}
                >
                    Enviar Mensaje
                </Button>
            </Grid>


        </Grid>

    )

}
export default Dialogo_Tienda_Medalla