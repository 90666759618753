import {fire} from "../../fire";
import {
    setDoc,
    doc,
    deleteDoc,
    onSnapshot,
    updateDoc,
    writeBatch,
    getDocs,
    collection,
    arrayUnion, query, where
} from 'firebase/firestore'

export const agregarItemArrayDocVerificar = ({col, id, nombreArray, obj}) => {


    return new Promise(resolve => {

        let q = query(collection(fire, col), where(nombreArray, "array-contains", obj))

        getDocs(q).then((dox) => {

            if (dox && dox.docs.length === 0) {
                updateDoc(doc(fire, col, id), {[nombreArray]: arrayUnion(obj)}).then((dox) => {
                    return resolve({res: true, data: id})
                }).catch((err) => {
                    //  alert(err.message)
                    return resolve({res: false, data: err.message})
                })
            }
        })


    })

}