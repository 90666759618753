import {obtenerDoc} from "../Servicios/BD/obtenerDoc";
import {enviarNotificacionToken} from "../Modulo_Notificaciones/Funciones/enviarNotificacionToken";

export const enviarNotificacionUsuario = ({idUsuario, titulo, mensaje}) => {

    obtenerDoc('usuarios', idUsuario).then((dox) => {
        if (dox.res) {
            let token = dox.data.token
            if (token) {
                enviarNotificacionToken({token: token, titulo: titulo, mensaje: mensaje})
            }
        }
    })

}