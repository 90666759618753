import {useEffect, useState} from "react";
import {getMessaging, getToken} from "firebase/messaging";
import {CERTIFICADOPUSH} from "../Constantes";
import {app, fire} from "../../fire";
import {verificarPermisoNotificacion} from "../Utilidades/verificarPermisoNotificacion";

export const useGetToken = () => {
    const [token, setToken] = useState(null)


    const messaging = getMessaging(app)

    useEffect(() => {


        if (verificarPermisoNotificacion()) {
            getToken(messaging, {vapidKey: CERTIFICADOPUSH}).then((currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    // ...
                    setToken(currentToken)
                   // console.log(currentToken)
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        } else {
            verificarPermisoNotificacion()
        }


    }, []);
    return {
        token
    }
}