import {DIVIPOLA} from "./ConstantesUtilidades";


export const obtenerDepartamentos = (pais) => {

    let arr = DIVIPOLA.map((it) => it.NOMBRE_DEPTO)

    if (pais !== 'Colombia'){
        //DIVIPOLAECUADOR
    }



    let set = new Set(arr)

    let result = [...set]

    return result

}