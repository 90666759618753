/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import DashboardBase from "../Modulo_dashboard/Componentes/DashboardBase";
import MenuLateral from "../Modulo_dashboard/Componentes/MenuLateral";
import logo from '../Recursos/logo_oscuro.svg'
import logo_blanco from '../Recursos/logo_blanco.svg'
import logo_redondo from '../Recursos/logo_redondo.svg'
import {useContext, useEffect} from "react";
import {usuarioContext} from "../App";
import {funSalir} from "../Servicios/Auth/funSalir";
import {useGetToken} from "../Modulo_Notificaciones/Hooks/useGetToken";
import {useEscucharNotificacion} from "../Modulo_Notificaciones/Hooks/useEscucharNotificacion";
import {actualizarDoc} from "../Servicios/BD/actualizarDoc";
import Seccion_Potenciales from "./Secciones/Seccion_Potenciales";
import {
    DashboardOutlined, Inventory2Outlined,
    MilitaryTech,
    PersonAdd,
    PolicyOutlined,
    StoreOutlined,
    WorkOutline
} from "@mui/icons-material";
import {agregarItemArrayDocVerificar} from "../Servicios/BD/agregarItemArrayDocVerificar";
import Seccion_Empresarias from "./Secciones/Seccion_Empresarias";
import Seccion_Revision_Tiendas from "./Secciones/Seccion_Revision_Tiendas";
import Seccion_Tiendas from "./Secciones/Seccion_Tiendas";
import Seccion_Medallas from "./Secciones/Seccion_Medallas";
import Seccion_Catalogo from "./Secciones/Seccion_Catalogo";


const Dashboard = () => {
    const cData = useContext(usuarioContext)
    const {Notificador} = useEscucharNotificacion()
    const {token} = useGetToken()

    const irPerfil = () => {
        // alert('ir Perfil')
    }

    useEffect(() => {
        if (cData.usuario) {
            let usuario = cData.usuario
            if (token && usuario.token !== token) {
                agregarItemArrayDocVerificar({col: 'tokens', id: 'adminTokens', nombreArray: 'tokens', obj: token}).then((dox) => {
                    if (dox.res){
                      //  console.log('token agregado')
                    }
                })
            }

        }

    }, [token]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Notificador/>

            <DashboardBase secciones={secciones}
                           credenciales={cData.usuario.credenciales ? cData.usuario.credenciales : []}>
                <MenuLateral logo={logo} clickPerfil={irPerfil} logoRedondo={logo_redondo} logoBlanco={logo_blanco}
                             usuario={cData.usuario} salir={funSalir}/>
            </DashboardBase>


        </Grid>
    )

}
export default Dashboard

const secciones = [
    {
        nombre: 'Potenciales',
        Componente: <Seccion_Potenciales/>,
        icono: PersonAdd,
        categoria: 'propia',
        camino: '/Potenciales',
    },
    {
        nombre: 'Empresarias',
        Componente: <Seccion_Empresarias/>,
        icono: WorkOutline,
        categoria: 'listas',
        camino: '/Empresarias',
    },
    {
        nombre: 'Tiendas',
        Componente: <Seccion_Tiendas/>,
        icono: StoreOutlined,
        categoria: 'listas',
        camino: '/Tiendas',
    },
    {
        nombre: 'Catalogo',
        Componente: <Seccion_Catalogo/>,
        icono: Inventory2Outlined,
        categoria: 'listas',
        camino: '/CatalogoGe',
    },
    {
        nombre: 'Rev. Tiendas',
        Componente: <Seccion_Revision_Tiendas/>,
        icono: PolicyOutlined,
        categoria: 'revisiones',
        camino: '/RevTiendas',
    },
    {
        nombre: 'Medallas',
        Componente: <Seccion_Medallas/>,
        icono: MilitaryTech,
        categoria: 'Configuraciones',
        camino: '/Medallas',
    },
]