/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {TaskAlt} from "@mui/icons-material";
import {useContext} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {usuarioContext} from "../../App";
import Formulario_Producto_Basico from "../Formularios/Formulario_Producto_Basico";
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";
import {remplazarUrlToBlob} from "../../Utilidades/remplazarUrlToBlob";


const Dialogo_Producto_Variante = ({realizado, datos, cerrar}) => {
    const cData = useContext(usuarioContext)
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {

            abrirCargador('Subiendo imagenes y datos')
            let obj = entidad
            if (obj.estado) {
                obj.estado = 'En revision'
            }

            let arrVariantes = entidad.variantes
            let arrImgBlob = arrVariantes.map((it) => it.img)
            subirVariasImagenFile(arrImgBlob, 'variantesProductos').then((dox) => {
                if (dox.res) {
                    let urls = dox.data
                    let arrVariantesUrl = remplazarUrlToBlob(urls, arrVariantes)
                    obj.variantes = arrVariantesUrl
                    guardarProducto(obj)
                } else {
                    alert('Ups, algo inesperado paso, por favor intentalo nuevamente\nError: #DVE01')
                    cerrarCargador()
                }
            })
        })

    }

    const guardarProducto = (obj) => {
        guardarDoc('productos', obj).then((dox) => {
            if (dox.res) {
                realizado()
                cerrar()
                alert('Cambios realizados con exito')
            } else {
                alert('Ups, algo inesperado paso, intentalo nuevamente\nError: #DVE02')
            }
            cerrarCargador()
        })
    }


    const borrar = () => {
        abrirCargador('Borrando Producto')
        borrarDoc('productos', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Producto_Basico props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>

        </Grid>

    )

}
export default Dialogo_Producto_Variante