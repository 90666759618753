/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, IconButton, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment,
    Facebook,
    HomeWork,
    Instagram,
    Link,
    LocationCity,
    Policy,
    Store,
    Warehouse
} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {PAISES} from "../../Constantes";
import {useEffect, useState} from "react";
import IngresoSelectFuncion from "../../Modulo_formularios/Componentes/IngresoSelectFuncion";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import {obtenerDepartamentos} from "../../Utilidades/obtenerDepartamentos";
import {obtenerCiudades} from "../../Utilidades/obtenerCiudades";
import {irUrl} from "../../Utilidades/irUrl";
import IngresoCheckGrupoMulti from "../../Modulo_formularios/Componentes/IngresoCheckGrupoMulti";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";

const Formulario_Tienda_Dialogo = ({...props}) => {
    const [pais, setPais] = useState('')
    const [departamento, setDepartamento] = useState('')
    const [categorias, setCategorias] = useState([])

    const verLink = (dato) => {

        let link = props.props.getValues(dato)
        irUrl(link)

    }

    useEffect(() => {
        obtenerCol('categoriasGenerales').then((dox) => {
            if (dox.res) {

                let catNombre = dox.data.map((it) => it.nombre)
                setCategorias(catNombre)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                    <IngresoImagenCrop dato={'logo'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Store} nombre={'Nombre de la tienda'}
                                  dato={'nombre'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion corta de la tienda'}
                                  dato={'descripcion'}  {...props}
                                  lineas={6}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoCheckGrupoMulti nombre={'Categorias Generales'} dato={'categorias'} {...props}
                                            opciones={categorias}/>
                </Grid>


                <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Facebook} nombre={'Link de Facebook'}
                                  dato={'facebook'}  {...props}

                    />
                </Grid>

                <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start', ml: -0.5}}>
                    <IconButton
                        onClick={() => verLink('facebook')}
                    >
                        <Policy sx={{width: 30, height: 30}}/>
                    </IconButton>
                </Grid>

                <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Instagram} nombre={'Link de instagram'}
                                  dato={'instagram'}  {...props}

                    />
                </Grid>

                <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start', ml: -0.5}}>
                    <IconButton
                        onClick={() => verLink('instagram')}
                    >
                        <Policy sx={{width: 30, height: 30}}/>
                    </IconButton>
                </Grid>

                <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Link} nombre={'Link de Tiktok'}
                                  dato={'tiktok'}  {...props}

                    />
                </Grid>

                <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start', ml: -0.5}}>
                    <IconButton
                        onClick={() => verLink('tiktok')}
                    >
                        <Policy sx={{width: 30, height: 30}}/>
                    </IconButton>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Datos de bodega</Typography>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectFuncion Icono={Apartment} nombre={'Pais'}
                                          dato={'pais'}  {...props} opciones={PAISES} setDato={setPais}

                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectFuncion Icono={LocationCity} nombre={'Municipio / Provincia'}
                                          dato={'departamento'} opciones={obtenerDepartamentos(pais)}
                                          setDato={setDepartamento}
                                          {...props}

                    />
                </Grid>


                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={HomeWork} nombre={'Ciudad'}
                                   dato={'ciudad'}  {...props} opciones={obtenerCiudades(departamento, pais)}

                    />
                </Grid>

                <Grid item container lg={12} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Warehouse} nombre={'Direccion de bodega'}
                                  dato={'direccion'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 6}}>
                    <Typography>Datos y foto de empresaria</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                    <IngresoImagenCrop dato={'imgEmpresaria'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre o con el que se identifica '}
                                  dato={'empresaria'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion de quien eres'}
                                  dato={'descripcionEmpresaria'}  {...props}
                                  lineas={6}
                    />
                </Grid>

                <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Facebook} nombre={'Link de Facebook'}
                                  dato={'facebookEmpresaria'}  {...props}

                    />
                </Grid>

                <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start', ml: -0.5}}>
                    <IconButton
                        onClick={() => verLink('facebookEmpresaria')}
                    >
                        <Policy sx={{width: 30, height: 30}}/>
                    </IconButton>
                </Grid>

                <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Instagram} nombre={'Link de instagram'}
                                  dato={'instagramEmpresaria'}  {...props}

                    />
                </Grid>

                <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start', ml: -0.5}}>
                    <IconButton
                        onClick={() => verLink('instagramEmpresaria')}
                    >
                        <Policy sx={{width: 30, height: 30}}/>
                    </IconButton>
                </Grid>

                <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Link} nombre={'Link de Tiktok'}
                                  dato={'tiktokEmpresaria'}  {...props}

                    />
                </Grid>

                <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start', ml: -0.5}}>
                    <IconButton
                        onClick={() => verLink('tiktokEmpresaria')}
                    >
                        <Policy sx={{width: 30, height: 30}}/>
                    </IconButton>
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Tienda_Dialogo