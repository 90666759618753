export const MOSTRAREN = ['Home', 'Catalogo']

export const ESTADOSPOTENCIALES = ['Nueva', 'Contactada', 'Aceptada', 'Archivada']

export const ESTADOSEMPRESARIAS = ['Nueva', 'Pendiente', 'Registrada']

export const PAISES = ['Colombia', 'Ecuador']

export const ESTADOTIENDAS = ['En revision', 'Ajustes pendientes', 'Publicada', 'Suspendida']

export const TIPOPRODUCTOS = ['Listo', 'Bajo pedido']

export const TIPOMONEDA = ['Pesos Colombianos', 'Dolares']

export const ESTADOPRODUCTOS = ['Publicado', 'En Revision', 'Ajustes pendientes']