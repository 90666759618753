/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOTIENDAS} from "../../Constantes";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import {ManageSearch, TaskAlt} from "@mui/icons-material";
import Dialogo_Revision_Tienda from "../Dialogos/Dialogo_Revision_Tienda";
import Dialogo_Revision_Tienda_Aprobar from "../Dialogos/Dialogo_Revision_Tienda_Aprobar";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";

const Seccion_Revision_Tiendas = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numTiendas, setNumTiendas] = useState(0)
    const [numTiendasRevision, setNumTiendasRevision] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'revision_tiendas',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Revision_Tienda,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalles de la tienda'
    })
    const {Dialogo: DialogoAprobar, abrir: abrirAprobar} = useDialogo({
        Componente: Dialogo_Revision_Tienda_Aprobar,
        realizado: () => setRecargar(!recargar),
        titulo: 'Aprobar o Ajustes tienda'
    })


    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'ver' :
                abrir(row)
                break
            case 'aprobar' :
                abrirAprobar(row)
                break

        }

    }


    useEffect(() => {

        contarPropiedad({coleccion: 'revision_tiendas', propiedad: 'estado', valor: 'En revision'}).then((dox) => {
            if (dox.res) {
                setNumTiendasRevision(dox.data)
            }
        })

        contarColeccion({coleccion: 'revision_tiendas'}).then((dox) => {
            if (dox.res) {
                setNumTiendas(dox.data)
            }
        })


        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoAprobar/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Revision Tiendas'} textoComplementario={'en revision'}
                                      textoResaltado={`${numTiendasRevision} Tiendas`} difiere={true}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    estados={ESTADOTIENDAS}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numTiendas}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Revision_Tiendas

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    },
    {
        nombre: 'Pais',
        direccion: 'right',
        propiedad: 'pais',

    },
    {
        nombre: 'Ciudad',
        direccion: 'right',
        propiedad: 'ciudad',

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    }
]


const botonesTabla = [
    {
        nombre: 'Ver Detalle',
        icono: ManageSearch,
        id: 'ver'
    },
    {
        nombre: 'Aprobar',
        icono: TaskAlt,
        id: 'aprobar'
    },

]


