/**************************************************
 * Nombre:       Tarjeta_Producto_Peque
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Avatar, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {useState} from "react";
import {palette} from "@mui/system";
import {useNavigate} from "react-router-dom";
import {obtenerDescuento} from "../../Utilidades/obtenerDescuento";

const Tarjeta_Producto_Peque = ({item = {}, difiere = false}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const navigate = useNavigate()
    const [adentro, setAdentro] = useState(false)

    return (
        <ButtonBase sx={{
            width: '100%', textAlign: 'left',
        }}
                    onMouseEnter={() => setAdentro(true)}
                    onMouseLeave={() => setAdentro(false)}
                   // onClick={() => navigate(`/Detalles/${item.id}`)}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"

            >

                {!difiere && <Grid item container lg={12} sm={12} xs={12}
                                   sx={{
                                       justifyContent: 'flex-start',
                                       marginBottom: sCell ? -5 : -6,
                                       ml: -3,
                                       zIndex: 2
                                   }}>
                    <Avatar src={''} sx={{
                        borderColor: theme.palette.primary.main,
                        height: sCell ? 60 : 70,
                        width: sCell ? 60 : 70
                    }}/>
                </Grid>
                }


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-end', marginBottom: -10, zIndex: 2}}>
                    {item.rebajado && <Typography sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: '#fff',
                        pt: 0.7,
                        pb: 0.5,
                        pl: 2.4,
                        pr: 2,
                        fontWeight: 500
                    }}>{`${obtenerDescuento({
                        valorNormal: item.valor,
                        valorRebajado: item.rebajado
                    })}%`}</Typography>
                    }
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', zIndex: 1}}>
                    <img src={item.img} width={'100%'} height={'auto'} alt={item.nombre}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            transition: 'all .2s ease-in-out',
                            backgroundColor: adentro ? theme.palette.primary.main : '#F5F7F2', p: 2,
                            color: adentro ? '#fff' : '#000'

                        }}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{
                                fontWeight: 600,
                                fontSize: sCell ? 16 : 18,
                                lineHeight: 1.2
                            }}>{item.nombre}</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            {item.rebajado && item.rebajado > 0 &&
                                <Typography sx={{
                                    fontWeight: 400,
                                    fontSize: sCell ? 12 : 14,
                                    fontFamily: 'Lato',
                                    color: adentro ? '#ffffff90' : '#00000090'
                                }}>Antes
                                    <span style={{
                                        textDecorationLine: 'line-through',
                                        paddingLeft: 10
                                    }}>{formatoMoneda(item.valor)}</span>
                                </Typography>
                            }
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    color: adentro ? '#fff' : theme.palette.primary.main,
                                    fontFamily: 'Lato',
                                    fontSize: sCell ? 14 : 16
                                }}>{formatoMoneda(item.rebajado && item.rebajado > 0 ? item.rebajado : item.valor)}</Typography>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>
        </ButtonBase>
    )

}
export default Tarjeta_Producto_Peque