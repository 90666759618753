/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Divider, Grid, Typography, useMediaQuery} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment,
    Facebook, Flag,
    HomeWork,
    Instagram,
    Link,
    LocationCity,
    Store, Storefront,
    Warehouse
} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOTIENDAS, PAISES} from "../../Constantes";
import {useEffect, useState} from "react";
import IngresoSelectFuncion from "../../Modulo_formularios/Componentes/IngresoSelectFuncion";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import {obtenerDepartamentos} from "../../Utilidades/obtenerDepartamentos";
import {obtenerCiudades} from "../../Utilidades/obtenerCiudades";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoCheckGrupoMulti from "../../Modulo_formularios/Componentes/IngresoCheckGrupoMulti";
import {theme} from "../../Tema";

const Formulario_Empresaria_Tienda = ({...props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [pais, setPais] = useState('')
    const [departamento, setDepartamento] = useState('')
    const [categorias, setCategorias] = useState([])
    const [estado, setEstado] = useState('')


    useEffect(() => {
        obtenerCol('categoriasGenerales').then((dox) => {
            if (dox.res) {

                let catNombre = dox.data.map((it) => it.nombre)
                setCategorias(catNombre)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectFuncion Icono={Flag} nombre={'Estado'}
                                          dato={'estado'}  {...props} setDato={setEstado}
                                          opciones={ESTADOTIENDAS}

                    />
                </Grid>

                {estado === 'Publicada' &&
                    <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                        <Button startIcon={<Storefront/>} >Ver Tienda</Button>
                    </Grid>
                }


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                    <IngresoImagenCrop dato={'logo'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Store} nombre={'Nombre de la tienda'}
                                  dato={'nombre'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion corta de la tienda'}
                                  dato={'descripcion'}  {...props}
                                  lineas={6}
                    />
                </Grid>


                <Grid item container lg={12} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Facebook} nombre={'Link de Facebook'}
                                  dato={'facebook'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Instagram} nombre={'Link de instagram'}
                                  dato={'instagram'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Link} nombre={'Link de Tiktok'}
                                  dato={'tiktok'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Divider sx={{width: '100%'}}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoCheckGrupoMulti nombre={"Tipos de productos que vende"} dato={'categorias'} {...props}
                                            opciones={categorias}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Divider sx={{width: '100%'}}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Datos de bodega</Typography>
                </Grid>


                <Grid item container lg={12} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoSelectFuncion Icono={Apartment} nombre={'Pais'}
                                          dato={'pais'}  {...props} opciones={PAISES} setDato={setPais}

                    />
                </Grid>


                {pais === 'Colombia' ?

                    <>
                        <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                            <IngresoSelectFuncion Icono={LocationCity} nombre={'Departamento'}
                                                  dato={'departamento'} opciones={obtenerDepartamentos(pais)}
                                                  setDato={setDepartamento}
                                                  {...props}

                            />
                        </Grid>


                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                            <IngresoSelect Icono={HomeWork} nombre={'Ciudad'}
                                           dato={'ciudad'}  {...props}
                                           opciones={obtenerCiudades(departamento, pais)}

                            />
                        </Grid>

                    </>

                    :

                    <>
                        <Grid item container lg={12} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                            <IngresoTexto Icono={LocationCity} nombre={'Provincia'}
                                          dato={'departamento'} opciones={obtenerDepartamentos(pais)}
                                          setDato={setDepartamento}
                                          {...props}

                            />
                        </Grid>


                        <Grid item container lg={12} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                            <IngresoTexto Icono={HomeWork} nombre={'Ciudad'}
                                          dato={'ciudad'}  {...props}
                                          opciones={obtenerCiudades(departamento, pais)}

                            />
                        </Grid>

                    </>


                }


                <Grid item container lg={12} sm={6} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Warehouse} nombre={'Direccion de bodega'}
                                  dato={'direccion'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Divider sx={{width: '100%'}}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                    <Typography>Datos y foto de empresaria</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                    <IngresoImagenCrop dato={'imgEmpresaria'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'center', marginTop: sCell ? 2 : 0}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre o con el que se identifica '}
                                  dato={'empresaria'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion de quien eres'}
                                  dato={'descripcionEmpresaria'}  {...props}
                                  lineas={6}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Empresaria_Tienda