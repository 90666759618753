/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOTIENDAS} from "../../Constantes";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import {CloudDownload, Edit, ManageSearch, MilitaryTech, TaskAlt} from "@mui/icons-material";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {LoadingButton} from "@mui/lab";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";

import Dialogo_Tienda from "../Dialogos/Dialogo_Tienda";
import {obtenerPorcentajeDosValores} from "../../Utilidades/obtenerPorcentajeDosValores";
import Dialogo_Tienda_Estado from "../Dialogos/Dialogo_Tienda_Estado";
import Dialogo_Tienda_Medalla from "../Dialogos/Dialogo_Tienda_Medalla";

const Seccion_Tiendas = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'tiendas',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Tienda, realizado: () => setRecargar(!recargar)})
    const {Dialogo: DialogoEstado, abrir: abrirEstado} = useDialogo({
        Componente: Dialogo_Tienda_Estado,
        realizado: () => setRecargar(!recargar),
        titulo: 'Estado Tienda'
    })
    const {Dialogo: DialogoMedalla, abrir: abrirMedalla} = useDialogo({
        Componente: Dialogo_Tienda_Medalla,
        realizado: () => setRecargar(!recargar),
        titulo: 'Medallas'
    })
    const [valoresPedidos, setValoresPedidos] = useState([])
    const [cargando, setCargando] = useState(false)

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'editar' :
                abrir(row)
                break
            case 'estado' :
                abrirEstado(row)
                break
            case 'medallas' :
                abrirMedalla(row)
                break
        }

    }


    const llenarValores = () => {


        contarColeccion({coleccion: 'tiendas'}).then((dox) => {
            if (dox.res) {
                let numEmpresarias = dox.data
                contarPropiedad({coleccion: 'tiendas', propiedad: 'estado', valor: 'Publicada'}).then((des) => {
                    if (des.res) {
                        let numRegistradas = des.data
                        let valores = adaptador_indicador({
                            titulo: 'Total tiendas',
                            valorActual: numEmpresarias,
                            valorAnterior: `${obtenerPorcentajeDosValores(numRegistradas, numEmpresarias)}%`,
                            detalle: 'Publicadas',
                            tipo: 'nomoneda'
                        })

                        setValoresPedidos([valores])


                    }
                })

            }
        })
    }

    const exportarDatos = () => {
        setCargando(true)
        obtenerCol('empresarias').then((dox) => {
            if (dox.res) {
                let arr = dox.data
                const worksheet = XLSX.utils.json_to_sheet(arr);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                // Buffer to store the generated Excel file
                const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

                saveAs(blob, "Potenciales.xlsx");
            }
            setCargando(false)
        })
    }


    useEffect(() => {

        contarPropiedad({coleccion: 'tiendas', propiedad: 'estado', valor: 'Publicada'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })

        llenarValores()

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoMedalla/>

            <DialogoEstado/>

            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Tiendas'} textoComplementario={'publicadas'}
                                      textoResaltado={`${numProductos} Tiendas`}/>
            </Grid>

            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Indicadores valores={valoresPedidos} dir={'flex-end'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-end', marginTop: 2, marginBottom: -3}}>
                <LoadingButton variant={'contained'}
                               loading={cargando}
                               onClick={() => exportarDatos()}
                               endIcon={<CloudDownload/>}>Exportar datos</LoadingButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    estados={ESTADOTIENDAS}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Tiendas

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    },
    {
        nombre: 'Pais',
        direccion: 'right',
        propiedad: 'pais',

    },
    {
        nombre: 'Ciudad',
        direccion: 'right',
        propiedad: 'ciudad',

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    }
]


const botonesTabla = [
    {
        nombre: 'Editar',
        icono: ManageSearch,
        id: 'editar'
    },
    {
        nombre: 'Medallas',
        icono: MilitaryTech,
        id: 'medallas'
    },
    {
        nombre: 'Estado',
        icono: TaskAlt,
        id: 'estado'
    },


]


