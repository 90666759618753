import {DIVIPOLA} from "./ConstantesUtilidades";

export const obtenerCiudades = (departamento, pais) => {

    if (!departamento) {
        return []
    }

    let arr = DIVIPOLA

    if (pais !== 'Colombia') {
        //Cambiar a DIVIPOLAECUADOR
        arr = []
    }

    let arrEst = arr.map((it) => {

        if (it.NOMBRE_DEPTO === departamento) {
            return it.Nombre
        }
    })

    let set = new Set(arrEst)

    let result = [...set]

    return result.sort()

}