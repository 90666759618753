export const obtenerVarianteMenorValor = (arr) => {

    let variante = {}
    for (let i = 0; i < arr.length; i++) {
        if (variante && variante.valor) {
            if (variante.valor > arr[i].valor) {
                variante = arr[i]
            }
        } else {
            variante = arr[i]
        }
    }

    return variante
}