/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Divider, Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {TaskAlt} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Estado_Comentarios from "../Formularios/Formulario_Estado_Comentarios";
import {obtenerDescuento} from "../../Utilidades/obtenerDescuento";
import {obtenerVarianteMenorValor} from "../../Utilidades/obtenerVarianteMenorValor";
import Tarjeta_Producto_Peque from "../Tarjetas/Tarjeta_Producto_Peque";
import {guardarRegistro} from "../../Modulo_Algolia/Funciones/guardarRegistro";


const Dialogo_Producto_Enviar = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const [varianteMenorValor, setVarianteMenorValor] = useState({})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        let obj = datos
        obj.valor = varianteMenorValor.valor
        obj.rebajado = varianteMenorValor.rebajado
        obj.descuento = obtenerDescuento({
            valorNormal: varianteMenorValor.valor,
            valorRebajado: varianteMenorValor.rebajado
        })

        obj.estado = 'Publicado'
        obj.fecha = new Date().getTime()

        abrirCargador('Enviando Datos')
        guardarDoc('productos', obj).then((dox) => {
            if (dox.res) {

                guardarRegistro({obj: obj, coleccion: 'productos', id: obj.id}).then((den) => {
                    realizado()
                    cerrar()
                    alert('Cambios guardados con exito')
                })

            } else {
                alert('Ups, algo inesperado paso, intentalo nuevamente\nError: #DPE01')
            }
            cerrarCargador()
        })

    }


    const enviarComentario = () => {

        obtenerEntidad().then((entidad) => {
            let obj = entidad
            abrirCargador('Enviando Datos')
            guardarDoc('productos', obj).then((dox) => {
                if (dox.res) {
                    realizado()
                    cerrar()
                    alert('Cambios guardados con exito')
                } else {
                    alert('Ups, algo inesperado paso, intentalo nuevamente\nError: #DPE02')
                }
                cerrarCargador()
            })
        })


    }

    useEffect(() => {

        if (datos && datos.variantes) {
            let variantes = datos.variantes

            let menorValor = obtenerVarianteMenorValor(variantes)

            menorValor.img = datos.img
            menorValor.nombre = datos.nombre

            setVarianteMenorValor(menorValor)

        }

    }, [datos]);
    return (

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={7} sm={8} xs={8} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Tarjeta_Producto_Peque item={varianteMenorValor} difiere={true}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Divider sx={{width: '100%'}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Typography sx={{textAlign: 'center', fontSize: 14}}>
                    Al aprobar el producto este sera visible en el marketplace y ya no se vera en revision de catalogo
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Aprobar producto
                </Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Divider sx={{width: '100%'}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                <Formulario_Estado_Comentarios props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => enviarComentario()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>


        </Grid>

    )

}
export default Dialogo_Producto_Enviar