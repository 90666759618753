export const remplazarUrlToBlob = (arrUrl, arrVariantes) => {

    let arr = []

    for (let i = 0; i < arrVariantes.length; i++) {
        arrVariantes[i].img = arrUrl[i]
        let obj = {...arrVariantes[i]}
        obj.img = arrUrl[i]
        arr.push(obj)
    }

    return arr

}