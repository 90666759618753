import {formatoUrl} from "../Utilidades/formatoUrl";

export const enviarLinkRegistro = (entidad) => {

    let texto = `Hola ${entidad.nombre} felicidades por hacer parte de esta red de empresarias unidas, por favor crea tu usuario y contraseña dando click en el siguiente enlace`

    let link = 'https://mujeresempresariasunidas.com/Registro/' + entidad.id

    let textoUrl = formatoUrl(texto)

    let indicador = '593'

    if (entidad.pais === 'Colombia') {
        indicador = '57'
    }

    let numero = entidad.celular.replaceAll(' ', '')

    let mensaje = `https://api.whatsapp.com/send?phone=${indicador}${numero}&text=${textoUrl}%0A%0A${link}`

    window.open(mensaje)

}