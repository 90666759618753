/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, IconButton, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment,
    Facebook, Flag,
    HomeWork,
    Instagram,
    Link,
    LocationCity, MilitaryTech,
    Policy,
    Store,
    Warehouse
} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {PAISES} from "../../Constantes";
import {useEffect, useState} from "react";
import IngresoSelectFuncion from "../../Modulo_formularios/Componentes/IngresoSelectFuncion";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import {obtenerDepartamentos} from "../../Utilidades/obtenerDepartamentos";
import {obtenerCiudades} from "../../Utilidades/obtenerCiudades";
import {irUrl} from "../../Utilidades/irUrl";
import IngresoCheckGrupoMulti from "../../Modulo_formularios/Componentes/IngresoCheckGrupoMulti";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";

const Formulario_Medalla = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                    <IngresoImagenCrop ancho={1} alto={2} dato={'img'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={MilitaryTech} nombre={'Nombre de la medalla'}
                                  dato={'nombre'}  {...props} requerido={'El nombre de la medalla es requerido'}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion interna'}
                                  dato={'descripcion'}  {...props}
                                  requerido={'La descripcion de la medalla es requerida'}
                                  lineas={6}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Flag} nombre={'Tipo de medalla'} requerido={'El tipo de medalla es requerido'}
                                   dato={'tipo'} opciones={['Default', 'Privada']} {...props}

                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Medalla