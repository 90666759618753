/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Checkbox, Divider, Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {TaskAlt, WhatsApp} from "@mui/icons-material";
import {useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Tienda_Basica from "../Formularios/Formulario_Tienda_Basica";
import Formulario_Estado_Comentarios from "../Formularios/Formulario_Estado_Comentarios";
import {enviarNotificacionUsuario} from "../../Utilidades/enviarNotificacionUsuario";
import {enviarWhatsAppEmpresaria} from "../../Utilidades/enviarWhatsAppEmpresaria";


const Dialogo_Revision_Tienda_Aprobar = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })
    const [entendido, setEntendido] = useState(false)


    const aprobarRevision = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando datos')
            let obj = entidad
            obj.estado = 'Publicada'
            guardarTienda(obj, 'tiendas')

        })
    }
    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando datos')
            guardarTienda(entidad, 'revision_tiendas')

        })

    }

    const guardarTienda = (entidad, coleccion) => {

        guardarDoc(coleccion, entidad).then((dox) => {
            if (dox.res) {

                if (coleccion === 'tiendas') {
                    borrarDoc('revision_tiendas', entidad.id).then((des) => {
                        enviarNotificacionUsuario({
                            idUsuario: entidad.idPersona,
                            titulo: 'Tu tienda sera publicada',
                            mensaje: 'Felicitaciones cambios aprobados, agrega o modifica tus productos si es el caso'
                        })
                    })
                    cerrarCargador()
                    alert('Cambios guardados de manera exitosa')
                    cerrar()
                    realizado()
                } else {
                    cerrarCargador()
                    enviarNotificacionUsuario({
                        idUsuario: entidad.idPersona,
                        titulo: `El estado de tu tienda es ${entidad.estado}`,
                        mensaje: 'Revisa el estado de tu tienda y realializa los ajustes si es el caso'
                    })
                    alert('Cambios guardados de manera exitosa')
                    cerrar()
                    realizado()
                }

            } else {
                cerrarCargador()
                alert('Ups, algo inesperado paso, intentalo nuevamente\nError: DT01')
            }

        })
    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Tienda_Basica props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start', marginTop: -0.8}}>
                        <Checkbox checked={entendido}
                                  onChange={(e) => setEntendido(!entendido)} size={'small'}/>
                    </Grid>

                    <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{fontSize: 14}}>
                            {'Entiendo que al aprobar esta revision los cambios se pulicaran en el marketplace'}

                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    disabled={!entendido}
                    onClick={() => aprobarRevision()}
                    startIcon={<TaskAlt/>}
                >
                    Apobar revision
                </Button>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 3, marginBottom: 3}}>
                <Divider sx={{width: '100%'}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Estado_Comentarios props={props}/>
            </Grid>

            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    onClick={() => guardarCambios()}
                >
                    Guardar Cambios
                </Button>
            </Grid>

            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button
                    disabled={!datos.idEmpresaria}
                    startIcon={<WhatsApp/>}
                    variant={'outlined'}
                    onClick={() => enviarWhatsAppEmpresaria({id: datos.idEmpresaria})}
                >
                    Enviar Mensaje
                </Button>
            </Grid>


        </Grid>

    )

}
export default Dialogo_Revision_Tienda_Aprobar