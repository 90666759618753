/**************************************************
 * Nombre:       Tarjeta_Variante_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";

const Tarjeta_Variante_Producto = ({item, click, index}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{borderBottom: 1, borderColor: '#00000050', py: 1, cursor: 'pointer'}}
            onClick={() => click(item, index)}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{fontWeight: 600}}>{item.nombre}</Typography>
                </Grid>

                {item.rebajado > 0 ?
                    <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{
                            fontSize: 12,
                            color: '#00000080'
                        }}>{`Valor venta: ${formatoMoneda(item.rebajado)}`}</Typography>
                    </Grid>

                    :

                    <Grid item container lg={6} sm={12} xs={6} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{
                            fontSize: 12,
                            color: '#00000080'
                        }}>{`Valor venta: ${formatoMoneda(item.valor)}`}</Typography>
                    </Grid>
                }
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Variante_Producto    